$Colors:(
  primary-color: #8ac53b,
  secondary-color: #40b1db,
    blue-color:#046db5,
   blackColor: #000000,
   whitColor: #ffffff,
   black-300: #333333,
   redColor: #eb1831,
  textColor: (
    primaryText: #333333,
    secondaryText: #666666
  ),
  greyColor: (
    light: #e8e8e8,
    dark:  #767676,
    grey-900: #999999,
    light-grey: #F2F3F6
  )
);



