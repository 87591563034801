.Signup{
  padding:50px 0;
  h3{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  &-steps{
    display: flex;
    position: relative;
    &:before{
      content:"";
      width:30%;
      height: 2px;
      background-color: shadecolor(greyColor,grey-900);
      position: absolute;
      bottom:-20px;
      z-index: 1;
    }
   li{
    width: 15%;
    font-size: 30px;
    font-weight: bold;
    position: relative;
    z-index: 1;
    color:shadecolor(greyColor,grey-900);
    &:before{
      content:"";
      width:20px;
      height:20px;
      @include border-radius(50%);
      background-color: shadecolor(greyColor,grey-900);
      position: absolute;
      bottom:-28px;
    }
    span{
      background: color(whitColor);
    }
    &.active{
      color:color(blackColor);
      &:before{
        background-color: color(primary-color);
      }
      &:not(:last-child):after{
        content:"";
        width:100%;
        height: 2px;
        background-color: color(primary-color);
        position: absolute;
        bottom:-20px;
        z-index: 2;
        left:0
      }
     }
   }
  
  }
  &-form{
    padding:50px;
    @include box-shadow(5px,0px,29px,rgba(color(blackColor),0.2));
    margin-top: 75px;
    h3{
      margin: 50px 0 20px 0;
      font-size: 1.2rem;
    }
    .form-group:not(:first-child){
      margin-top: 40px;
    }
    label{
      font-weight: bold;
    }
    .custom-control{
      label{
        font-weight: normal;
      }
    }
  }
}