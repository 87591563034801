.event-summary{
  &-box{
    display: flex;
    color:color(primary-color);
    align-items: baseline;
    padding:10px;
    @include box-shadow(0px,0px,4px,rgba(color(blackColor),0.15));
    margin-bottom: 15px;
    background: color(whitColor);
    h6{
      font-size: 14px;
      font-weight: bold;
      color:color(primary-color);
      margin:0 10px;
    }
    span.data{
      color:color(blackColor);
      font-size: 14px;
    }
  &.details{
    flex-direction: column;
    h6{
      margin: 0 0 6px;
    }
   }
  }
h2{
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 15px;
}
  
}
.dashboard-tabs{
  margin-top: 50px;
  &-nav{
    display: flex;
    .nav-link{
      color:color(blackColor);
      background-color: shadecolor(greyColor,light);
      padding:0px 12px;
      min-width: 100px;
      text-align: center;
      margin-right: 10px;
      font-size: 14px;
      &.active,&:hover{
        color:color(whitColor);
        background-color: color(primary-color);
      }
    }
  }
  .tab-content{
    clear: both;
    .tab-pane{
      padding:30px 30px;
      @include box-shadow(0px,0px,6px,rgba(color(blackColor),0.2));
      background: color(whitColor);
    }
  }
  
}

.event-table{
  max-width: 100%;
  overflow-x: auto;
  .react-bootstrap-table{
    .table{
      thead{
        color:color(whitColor);
        background-color: shadecolor(greyColor,grey-500);
      }
      .innerHeading{
        td{
          padding: 0;
          border:none;
          font-size: 14px;
        }
      }
      tbody{
        & > tr:first-child{
          & > td{
            padding: 5px;
            background-color: shadecolor(greyColor,dark);
            color:color(whitColor);
          }
        }
      }
      .double-conent{
        width: 100%;
        td{
           border:none;
          font-size: 14px;
        }
      }
    }
  }
}
.event-table-top{
  margin:0px 0 30px;
  .request-outer{
    display: flex;
    margin-top: 15px;
  }
  .request{
    display: flex;
    align-items: center;
    margin-right: 30px;
    span{
      width: 20px;
      height: 20px;
      @include border-radius(6px);
      background-color: color(primary-color);
      display: inline-block;
      margin-right: 10px;
      &.multiple{
        background-color: color(blue-color);
      }
    }
  }
  .rt-btn{
    float: right;
  }
}
.tickets-summary{
  background-color: color(blue-color);
  padding: 20px;
  margin-bottom: 15px;
  p{
    color:color(whitColor);
    font-size: 15px;
    margin:5px 0 0 0;
  }
}

.event-popup{
  .modal-header{
    background-color: color(blue-color);
    color:color(whitColor);
    button{
      color:color(whitColor);
      opacity: 1;
    }
  }
}

.section-icon{
  width: 50px;
  height: 50px;
  background: color(whitColor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: color(primary-color);
  @include box-shadow(0px,0px,6px,rgba(color(blackColor),0.2));
  position: absolute;
  left:0;
}

.innerHeading{
  &>tr{
    &>td{
      text-align: center;
      padding:0 3px !important;
      font-size: 12px !important; 
      &:not(:last-child){
        border-right: 1px solid #dee2e6 !important;
      }
    }
  }
}

.double-conent{
  tbody{
    td{
      text-align: center;
      padding:0 5px;
    }
  }
}

.action-buttons{
  .btn{
    font-size: 12px;
    padding: 5px 7px;
    min-height: 25px;
    line-height: 16px;
      margin-bottom: 3px !important;
    & + .btn{
      margin-left: 3px;
    }
  }
}

.popover{
  &-header{
    background-color: color(whitColor);
    border: none;
  }
  .pop-content{
    margin-bottom: 20px;
    h6{
      font-size:13px;
      color: color(blue-color);
      font-weight: bold;
      margin-bottom: 5px;
    }
    p{
      background: shadecolor(greyColor,light);
      padding: 3px;
      margin: 0 0 5px 0;
      font-weight: 600;
      font-size: 13px;
    }
  }
}

.info-span {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: shadecolor(greyColor,dark);
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  margin-left: 5px;
  font-weight: 300;
}

.event-name{
  color: color(whitColor);
  font-size: 20px;
  font-weight: bold;
}

.custom-breadcrumb{
  .breadcrumb{
    background: transparent;
  }
}

#ev-detail{
  .modal-footer{
    justify-content: center;
  }
}

.display-none{
  display: none;
}

.event-details{
  background: color(whitColor);
  padding: 50px 0px;
  @include box-shadow(0px,0px,6px,rgba(color(blackColor),0.05));
}