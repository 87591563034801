
.login-outer{
  background: url('./../../../../../assets/images/login-bg.jpg');
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .login{
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    @include box-shadow(0,0,19px,rgba(color(blackColor),0.2));
    @include border-radius(5px);
    overflow: hidden;
    min-height: 450px;
    &-left{
      background-color: shadecolor(greyColor, light);
      padding: 30px;
      flex:0 1 50%;
      align-items: center;
      display: flex;
      text-align: center;
      justify-content: center;
      img{
        mix-blend-mode: darken;
      }
    }
    &-right{
      padding: 30px;
      flex:0 1 50%;
      background: color(whitColor);
      h2{
        color: color(blue-color);
        font-weight: bold;
        position: relative;
        &:after{
          content:"";
          position: absolute;
          width: 50px;
          height:3px;
          background-color:color(primary-color) ;
          top:10px;
          bottom: 0;
          margin: auto 10px;
        }
      }
      .form-control{
        @include input('bottom',1px,solid,shadecolor(greyColor,grey-900));
      }
      form{
        margin-top: 60px;
        button{
          margin-top: 2rem;
          text-transform: uppercase;
        }
      }
      .account{
        margin-top: 15px;
        display: block;
        text-align: center;
      }
    }
    @include media-breakpoint-down(md){
      flex-direction: column;
      margin: 60px 0;
    }
  }
  
}